<template>
  <div class="loan_item">
    <div class="inner">
      <h3>CONTACT US</h3>
      <div class="left_cell">
        <p class="p1">Customer Service Email</p>
        <P class="p2">MiRuiqueza-service@outlook.com</P>
      </div>
      <div class="right_cell">
        <img src="@/assets/images/loanImg/bn_e.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loan_item{
  padding-bottom: 60px;
  .inner{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 36px;
      color: #333333;
    }
    .left_cell{
      float: left;
      width: 50%;
      padding: 80px 0;
      .p1{
        font-size: 18px;
        color: #333333;
        margin-bottom: 10px;
      }
      .p2{
        font-size: 16px;
        color: #999999;
        margin-bottom: 40px;
        line-height: 24px;
      }
    }
    .right_cell{
      float: left;
      width: 50%;
      // padding: 80px 0;
      img{
        width: 522px;
      }
    }
  }

}
</style>
